<template>
  <main>
    <div class="horiz-container">
      <h1 class="page-title">Реквизиты</h1>
      <p>
        <b>Название организации</b><br>
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "ИВЕНТ ХАБ"
      </p>
      <p>
        <b>Юридический адрес организации</b><br>
        117485, РОССИЯ, Г МОСКВА, УЛ БУТЛЕРОВА, Д 7Б, КВ ОФИС 268,
      </p>
      <p>
        <b>ИНН</b><br>
        7728470702
      </p>
      <p>
        <b>КПП</b><br>
        772801001
      </p>
      <p>
        <b>ОГРН</b><br>
        1197746327217
      </p>
      <p>
        <b>Расчетный счет</b><br>
        40702810010000534889
      </p>
      <p>
        <b>Банк</b><br>
        АО "ТИНЬКОФФ БАНК"
      </p>
      <p>
        <b>ИНН банка</b><br>
        7710140679
      </p>
      <p>
        <b>БИК банка</b><br>
        044525974
      </p>
      <p>
        <b>Корреспондентский счет банка</b><br>
        30101810145250000974
      </p>
      <p>
        <b>Юридический адрес банка</b><br>
        Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26
      </p>
    </div>
  </main>
</template>

<script setup>
useHead({
  title: 'Реквизиты'
})
</script>

<style scoped>
.page-title {
  margin-bottom: 16px;
}
ul {
  list-style: initial;
  padding-left: 20px;
}
li {
  list-style: initial;
}
p {
  margin: 5px 0;
}
</style>
